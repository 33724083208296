import React from 'react'

interface CustomLayout {
    children: any;
    className?:string;
}

const Layout = ({children, className=''}: CustomLayout) => {
  return (
    <div className={`w-full h-full inline-block z-0 p-5 desktop:p-24 ${className}`}>
        {children}
    </div>
  )
}

export default Layout