import { motion } from 'framer-motion';
import React from 'react';

const PageTransition = () => {
  return (
    <>
      <motion.div
        className="fixed left-0 right-0 top-full h-screen z-30 bg-primaryLight dark:bg-primaryDark"
        initial={{ y: "-100%", height: "100%" }}
        animate={{ y: "0%", height: "0%" }}
        exit={{ y: ["0%", "-100%"], height: ["0%", "100%"] }}
        transition={{ duration: 0.6, ease: "easeInOut" }}
      />
      <motion.div
        className="fixed left-0 right-0 top-full h-screen z-20 bg-lightBackground"
        initial={{ y: "-100%", height: "100%" }}
        animate={{ y: "0%", height: "0%" }}
        transition={{ delay: 0.2, duration: 0.8, ease: "easeInOut" }}
      />
      <motion.div
        className="fixed left-0 right-0 top-full h-screen z-10 bg-primaryLight dark:bg-primaryDark"
        initial={{ y: "-100%", height: "100%" }}
        animate={{ y: "0%", height: "0%" }}
        transition={{ delay: 0.4, duration: 0.8, ease: "easeInOut" }}
      />
    </>
  );
};

export default PageTransition;
