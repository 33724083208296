import React from 'react'

interface CustomLargeText {
    className?:string;
    text?: any;
}

export const LargeText = ({className='', text} : CustomLargeText) => {
  return (
    <div className='r-4'>
      <div className={`${className} pt-5`}>
        <div className="z-30 backdrop:block dark:text-lightBackground bg-lightBackground dark:bg-darkBackground shadowBoxes border-4 max-w-[15rem] tablet:max-w-[20rem] relative">
          <div className="p-4 text-center">
            <div className="flex flex-wrap">
              <div className="flex-1 text-base">{text}</div>
            </div>
          </div>
        </div>
        <div className="-z-30 select-none text-transparent border-darkText dark:border-lightText border-4 max-w-[15rem] tablet:max-w-[20rem] overflow-hidden absolute -mt-[26.5rem] mobile:-mt-[26.7rem] tablet:-mt-[20.7rem] ml-6">
          <div className="p-3 -mt-2">
            <div>
              <div className="text-base">{text}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}