import AnimatedHeader from "@/components/text/animated/AnimatedHeader";
import { LargeText } from "@/components/text/normalText/LargeText";
import Layout from "@/components/Layout";
import { SmallText1, SmallText2 } from "@/components/text/normalText/SmallText";
import Head from "next/head";
import Link from "next/link";
import { AiOutlineDownload } from "react-icons/ai";
import { motion } from "framer-motion";
import PageTransition from "@/components/PageTransition";
import CV from "../CV.pdf";

export default function Home() {
  return (
    <>
      <Head>
        <title>Jeremiah Haastrup | Front-End Engineer in London, UK</title>
        <meta
          name="description"
          content="An experienced Front-End Engineer, dedicated to developing visionary large-scale web applications."
        />
        <meta
          name="google-site-verification"
          content="dlNDtSuXHSNulfPuDCA1D-DCOFE5OdplkiZkypH4WgI"
        />
        <meta
          property="twitter:image"
          content="https://res.cloudinary.com/dxrtssxet/image/upload/v1688211504/twitter_p9f5ar.png"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:description"
          content="An experienced Front-End Engineer, dedicated to developing visionary large-scale web applications."
        />
        <meta
          property="twitter:title"
          content="Jeremiah Haastrup | Front-End Engineer in London, UK"
        />
        <meta name="twitter:creator" content="@thetalesofj" />
        <meta
          property="og:description"
          content="An experienced Front-End Engineer, dedicated to developing visionary large-scale web applications."
        />
        <meta
          property="og:title"
          content="Jeremiah Haastrup | Front-End Engineer in London, UK"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dxrtssxet/image/upload/v1688211504/twitter_p9f5ar.png"
        />
        <meta property="og:url" content="https://jeremiahhaastrup.com/" />
      </Head>
      <PageTransition />
      <main className="flex items-start w-full h-full">
        <Layout>
          {/* <div className="py-5 w-full justify-end">
            <Link href="/contact">
              <button className="p-4 text-center backdrop:block dark:text-lightBackground bg-none shadowBoxes border-4 rounded-full max-w-[15rem] tablet:max-w-[20rem] relative">
                Available for Projects
              </button>
            </Link>
          </div> */}
          <div className="flex justify-between w-full">
            <div className="flex flex-wrap">
              <div className="flex flex-col justify-center tablet:pt-10 desktop:pt-0 text-center desktop:text-left desktop:w-1/2 large:pb-5">
                <AnimatedHeader text="FRONTEND" />
                <AnimatedHeader text="ENGINEER" />
                <p className="my-3 text-base dark:text-lightText desktop:max-w-sm tablet:px-24 desktop:px-0">
                  An experienced Front-End Engineer, dedicated to developing
                  visionary large-scale web applications.
                </p>
              </div>
              <div className="flex flex-wrap py-10 w-full justify-center desktop:w-1/2 desktop:justify-end desktop:pt-6">
                <LargeText text="I am Jeremiah Haastrup, a proficient Front-End Engineer committed to crafting cutting-edge, practical, and user-centric experiences. Drawing upon my extensive experience in project-based work, I continuously seek new and visually captivating approaches to transform clients' visions into reality" />
              </div>
              <div className="flex items-center justify-center w-full space-y-10 flex-col desktop:flex-row tablet:space-y-0 tablet:gap-10 tablet:pt-0 desktop:justify-between">
                <div className="flex flex-col space-y-10 desktop:space-y-0 desktop:flex-row desktop:gap-20">
                  <SmallText1 text=" A diverse skill-set with exceptional web development, web maintenance & engaging UX/UI designs." />
                  <SmallText2 text=" Proven ability in producing successful, optimised web applications for clients across several countries. " />
                </div>
                <div className="flex flex-col gap-10 largeMobile:flex-row desktop:flex-col large:flex-row desktop:gap-y-10">
                  <Link href="/portfolio">
                    <motion.button
                      className="group flex flex-row gap-2 justify-center items-center p-7 rounded-full px-11 bg-primaryLight dark:bg-primaryDark hover:bg-secondaryLight dark:hover:bg-secondaryDark text-lightText dark:text-darkText"
                      whileTap={{ scale: 0.95 }}
                      transition={{ duration: 1, type: "spring" }}
                    >
                      View Work
                    </motion.button>
                  </Link>
                  <a href={CV} target="_blank" download={true}>
                    <motion.button
                      className="flex flex-row gap-2 justify-center items-center p-7 rounded-full px-10 bg-primaryLight dark:bg-primaryDark hover:bg-secondaryLight dark:hover:bg-secondaryDark text-lightText dark:text-darkText"
                      whileTap={{ scale: 0.95 }}
                      transition={{ duration: 1, type: "spring" }}
                    >
                      Resume
                      <AiOutlineDownload />
                    </motion.button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </main>
    </>
  );
}
