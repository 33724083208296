import React from 'react'

interface CustomSmallText {
    text: string;
    className?: string;
}

export const SmallText1 = ({ className = '', text }: CustomSmallText) => {
  return (
    <div className='pr-4'>
      <div className={`${className} pt-5`}>
        <div className="z-30 block dark:text-lightBackground bg-lightBackground dark:bg-darkBackground shadowBoxes border-4 max-w-[15rem] relative">
          <div className="p-2 text-center">
            <div className="flex flex-wrap">
              <div className="flex-1 text-sm">{text}</div>
            </div>
          </div>
        </div>
        <div className="-z-30 select-none text-transparent border-darkText dark:border-lightText border-4 max-w-[15rem] overflow-hidden absolute mr-5 -mt-[9.2rem] ml-6">
          <div className="p-[2px] mobile:mt-4">
            <div>
              <div className="text-sm">{text}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const SmallText2 = ({ className = '', text }: CustomSmallText) => {
  return (
    <div className='pr-4'>
      <div className={`${className} pt-5`}>
        <div className="z-30 block dark:text-lightBackground bg-lightBackground dark:bg-darkBackground shadowBoxes border-4 max-w-[15rem] relative">
          <div className="p-2 py-[1.15rem] text-center">
            <div className="flex flex-wrap">
              <div className="flex-1 text-sm">{text}</div>
            </div>
          </div>
        </div>
        <div className="-z-30 select-none text-transparent border-darkText dark:border-lightText border-4 max-w-[15rem] overflow-hidden absolute mr-5 -mt-[9.25rem] ml-6">
          <div className="p-[2px] mt-6">
            <div>
              <div className="text-sm">{text}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

